import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { SocialsList } from './Socials.styles';

const Socials = (props) => {
  const data = useStaticQuery(graphql`
    query SocialLinksQuery {
      wp {
        optionsPage {
          optionsPage {
            socialLinks {
              link {
                url
                title
              }
              icon {
                databaseId
                localFile {
                  childImageSharp {
                    fixed(width: 55, height: 55) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const socials = data?.wp?.optionsPage?.optionsPage?.socialLinks;
  if (!socials) return null;
  return (
    <SocialsList {...props}>
      {socials.map(({ icon, link: { url, title } }) => (
        <li key={icon.databaseId}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            title={title}
            aria-label={`follow me on ${title}`}
          >
            <Img fixed={icon.localFile.childImageSharp.fixed} />
          </a>
        </li>
      ))}
    </SocialsList>
  );
};
export default Socials;
