import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import main_logo from 'assets/images/logo_corner.png';
import {
    HeaderWrapper,
    LogoWrapper,
    MenuButton,
    MenuWrapper,
    Menu,
    StyledSocials,
} from './Header.styles';

export const Header = ({ location }) => {
    const [isOpen, setOpen] = useState(false);
    const [isLogoVisible, setLogoVisible] = useState(location.pathname !== '/');

    const handleScroll = () => {
        const { innerHeight, pageYOffset } = window;
        if (typeof window !== 'undefined' && location.pathname === '/') {
            if (pageYOffset > innerHeight * 0.5) {
                setLogoVisible(true);
            } else {
                setLogoVisible(false);
            }
        }
    };

    useEffect(() => {
        setLogoVisible(location.pathname !== '/');
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [location.pathname]);

    return (
        <header>
            <HeaderWrapper isOpen={isOpen}>
                <LogoWrapper isVisible={isLogoVisible}>
                    <Link to="/" onClick={() => setOpen(false)}>
                        <img src={main_logo} alt="Logo" />
                    </Link>
                </LogoWrapper>
                <div className="column-right">
                    <MenuButton onClick={() => setOpen(!isOpen)}>
                        {isOpen ? 'Back' : 'Menu'}
                    </MenuButton>
                </div>
            </HeaderWrapper>
            <MenuWrapper isOpen={isOpen}>
                <Menu>
                    <li>
                        <Link to="/" onClick={() => setOpen(false)}>
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/about#contact"
                            onClick={() => setOpen(false)}
                        >
                            Contact
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={() => setOpen(false)}>
                            About Me
                        </Link>
                    </li>
                </Menu>
                <StyledSocials isHeader />
            </MenuWrapper>
        </header>
    );
};
