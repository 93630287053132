import styled from 'styled-components';

export const SocialsList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    ${({ theme }) => theme.mq.mobile} {
        justify-content: center;
    }
    li {
        margin: 0 20px;
        ${({ theme }) => theme.mq.mobile} {
            margin: 0 10px;
        }

        a {
            display: flex;

            img {
                width: 55px;
                height: 55px;
            }
        }
    }
`;
