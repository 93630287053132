import styled from 'styled-components';
import Socials from 'components/Socials/Socials';

export const HeaderWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 25px;
    ${({ theme }) => theme.mq.mobile} {
        padding: 20px 15px;
    }
`;

export const LogoWrapper = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.box_shadow()};
    background-color: ${({ theme }) => theme.colors.defaultBackground};
    transform: scale(${({ isVisible }) => (isVisible ? `1` : `0`)});
    transition: transform 250ms ease;
    ${({ theme }) => theme.mq.mobile} {
        width: 64px;
        height: 64px;
    }
    img {
        height: 64px;
        width: auto;
        ${({ theme }) => theme.mq.mobile} {
            height: 48px;
        }
    }
`;

export const MenuButton = styled.button`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.heading};
    background-color: ${({ theme }) => theme.colors.defaultBackground};
    box-shadow: 0 0 16px rgba(213, 180, 67, 0.35);
    font-family: 'WindsorD-Reg', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 300;
    text-align: center;
    padding: 15px 24px 10px;
    border: none;
    border-radius: 10px;
    font-size: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.mq.mobile} {
        padding: 12px 20px;
    }
    &,
    &:focus {
        outline: none;
    }
`;

export const MenuWrapper = styled.nav`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(${({ isOpen }) => (isOpen ? `0` : `100%`)});
    overflow: hidden;
    z-index: 99;
    transition: transform 300ms ease;
    background-color: ${({ theme }) => theme.colors.defaultBackground};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Menu = styled.ul`
    padding: 40px 0;
    margin: 0;
    ${({ theme }) => theme.mq.mobile} {
        padding: 0 0 55px;
    }
    li {
        padding: 50px 120px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.heading};
        ${({ theme }) => theme.mq.mobile} {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        a {
            display: block;
            color: ${({ theme }) => theme.colors.heading};
            font-family: 'WindsorD-Reg', -apple-system, BlinkMacSystemFont,
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
                'Helvetica Neue', sans-serif;
            text-decoration: none;
            font-size: 8.4rem;
            font-weight: 300px;
            line-height: 0.54;
            letter-spacing: -0.32px;
            transition: transform 250ms ease;
            text-align: center;
            white-space: nowrap;
            ${({ theme }) => theme.mq.mobile} {
                font-size: 6rem;
            }
            &:hover {
                transform: translateX(12px);
            }
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
`;

export const StyledSocials = styled(Socials)`
    margin-top: 90px;
    ${({ theme }) => theme.mq.mobile} {
        margin-top: 0;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
    }
`;
