module.exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
    if (location && location.hash) {
        const { offsetTop } = document.querySelector(`${location.hash}`);
        if (offsetTop) {
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    } else {
        window.scrollTo(0, 0);
    }

    return false;
};
