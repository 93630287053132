import React from 'react';
import Container from 'react-bootstrap/Container';
import Socials from 'components/Socials/Socials';
import { StyledFooter } from './Footer.styles';

export const Footer = () => (
    <StyledFooter>
        <Container fluid>
            <Socials />
        </Container>
    </StyledFooter>
);
