// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-homepage-homepage-js": () => import("./../../../src/templates/Homepage/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-homepage-js" */),
  "component---src-templates-page-about-page-about-js": () => import("./../../../src/templates/PageAbout/PageAbout.js" /* webpackChunkName: "component---src-templates-page-about-page-about-js" */),
  "component---src-templates-single-portfolio-item-single-portfolio-item-js": () => import("./../../../src/templates/SinglePortfolioItem/SinglePortfolioItem.js" /* webpackChunkName: "component---src-templates-single-portfolio-item-single-portfolio-item-js" */)
}

