import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
    ${({ theme }) => theme.mq.tablet} {
        font-size: 52.5%;
    }
}

body {
    &.root {
        background-color: ${({ theme }) => theme.colors.defaultBackground};
        color: ${({ theme }) => theme.colors.text};
        font-family: 'GT Walsheim', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
    }
}
ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p, h1, h2, h3, h4, h5, h6 {
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

[data-rmiz-overlay] {
    backdrop-filter: blur(3px);
    transition-property: background-color, backdrop-filter;
}
`;
