import React, { useState } from 'react';
import { theme } from 'layout/theme';
import { ThemeProvider } from 'styled-components';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import GlobalStyle from './Layout.styles';
import 'assets/styles/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ZoomContext } from './ZoomContext';

export default ({ children, location }) => {
  const [enableZoom, setEnableZoom] = useState(true);

  return (
    <ZoomContext.Provider value={[enableZoom, setEnableZoom]}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header location={location} />
        {children}
        <Footer />
      </ThemeProvider>
    </ZoomContext.Provider>
  );
};
