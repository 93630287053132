/**
 * theme.js
 * Exports theme settings like colors, media queries, fonts, etc.
 */

const colors = {
  white: '#ffffff',
  black: '#000000',
  almostBlack: '#4a4a4a',
  beige: '#f9f3dd',
  dirtyYellow: '#d5b443',
  red: '#e74c3c',
  orange: '#e67e22',
  green: '#2ecc71',
  blue: '#3498db',
  purple: '#8e44ad',
};

export const theme = {
  colors: {
    white: colors.white,
    black: colors.black,
    error: colors.red,
    heading: colors.dirtyYellow,
    defaultBackground: colors.beige,
    text: colors.almostBlack,
  },
  mq: {
    fhd: `@media(max-width: 1920px)`,
    laptop: `@media(max-width: 1600px)`,
    laptopMac: `@media(max-width: 1440px)`,
    laptop1366: `@media(max-width: 1366px)`,
    laptopMiddle: `@media(max-width: 1199px)`,
    tablet: `@media(max-width: 1024px)`,
    tabletMiddle: `@media(max-width: 991px)`,
    mobile: `@media(max-width: 767px)`,
    xs: `@media(max-width: 575px)`,
  },
  transition: {
    default: `250ms ease`,
  },
  box_shadow: (sx = 0, sy = 0, spread = 16, opacity = 0.35) =>
    `${sx}px ${sy}px ${spread}px rgba(213, 180, 67, ${opacity})`,
};
